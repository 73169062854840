import { CSSProperties, lazy, Suspense, useEffect } from 'react';

const LogoDark = lazy(() => import('./icons/LogoDark'));
const POSITION = 'fixed';

export default ({ position = POSITION }: { position?: CSSProperties['position'] }) => {
	useEffect(() => {
		document.getElementById('loading')?.remove();
	}, []);

	return (
		<div className='loading' style={{ position }}>
			<Suspense fallback={null}>
				<LogoDark size={100} />
			</Suspense>
			Porfavor Espere
			<div className='lds-ring'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};
