import { FC, lazy, Suspense } from 'react';

import LoadingComponent from 'components/LoadingComponent';
import { signOut } from 'firebase/auth';
import { authInstance } from 'helpers/initFirebase';
const AppNavigation = lazy(() => import('./AppNavigation'));

const App: FC = () => {
	/* signOut(authInstance); */
	return (
		<Suspense fallback={<LoadingComponent />}>
			<AppNavigation />
		</Suspense>
	);
};

export default App;

// by @xtealer, https://github.com/xtealer. Also find me on twitter as @xtealer00.
