import App from './App';
import registerServiceWorker from './serviceWorkerRegistration';
import { passiveSupport } from 'passive-events-support/src/utils';
import { render } from 'react-dom';
import { KEYS } from 'utils/constants';

passiveSupport({
	debug: false,
	// add this one
	listeners: [
		{
			element: 'div#root',
			event: 'wheel',
			prevented: true,
		},
		{
			element: 'div.ant-tabs-nav-wrap',
			event: 'wheel',
			prevented: true,
		},
		{
			element: 'input.ant-select-selection-search-input',
			event: 'touchstart',
			prevented: true,
		},
		{
			element: 'div.rc-virtual-list-scrollbar',
			event: 'touchstart',
			prevented: true,
		},
		{
			element: 'div.rc-virtual-list-scrollbar-thumb',
			event: 'touchstart',
			prevented: true,
		},
		{
			element: 'div.rc-virtual-list-holder',
			event: 'touchstart',
			prevented: true,
		},
		{
			element: 'div.rc-virtual-list-holder',
			event: 'wheel',
			prevented: true,
		},
	],
});

const savedTheme = localStorage.getItem(KEYS.ACTIVE_THEME);
const node = document.body;

if (typeof savedTheme === 'string' && node) {
	const devicePrefferedTheme = window?.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
	node.className = savedTheme ?? devicePrefferedTheme;
}

render(<App />, document.getElementById('root'));
registerServiceWorker();
