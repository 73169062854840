/**
 * The id's in these exports should match the same type that is stored on firebase firestore types collection
 */

import { ColDef } from '@ag-grid-community/all-modules';
import { globalColDef } from 'utils/agGrid';

export const INSURANCE_COMPANY = {
	_QUALITAS: '0',
};

export const REPORT_TYPE = {
	'0': {
		_CANCELATIONS: '0',
	},
};

export const DESKTOP_WIDTH = 1020;

/**
 * Unit of time is Seconds.
 */
export const TOOLTIP_DELAY = 1;

export const ROLES = {
	ROLES_CRUD: 'roles:crud',
	USERTYPES_CRUD: 'userTypes:crud',
};

export const USER_FORMS = {
	SUPER: 'super',
	ADMIN: 'admin',
	SELLER: 'seller',
	CUSTOMER: 'customer',
};

export const COLLECTIONS = {
	DEBTORS: 'debtors',
	TYPES: 'types',
	USER_TYPES: 'user_types',
	ROLES: 'roles',
	PARAMS: 'params',
	ORGANIZATIONS: 'organizations',
	CUSTOMERS: 'customers',
	USERS: 'users',
	PRODUCTS: 'products',
	INSURANCES: 'insurances',
	PAYMENTS: 'payments',
	MODULES: 'modules',
	GOALS: 'goals',
	VEHICLES: 'vehicles',
	INVOICES: 'invoices',
	PAYMENT_METHODS: 'payment_methods',
	CONTACT_REQUESTS: 'contact_requests',
	ADDRESS_COUNTRIES: 'address_countries',
	COUNTRY_STATES: 'country_states',
	COUNTRY_STATE_CITIES: 'country_state_cities',
	PERSONS: 'persons',
	QUOTATIONS: 'quotations',
	BAILS: 'bails',
	BONDS_APPLICATION: 'bonds_application',
	DEBTORS_BONDS: 'debtors_bons',
	DEBTORS_INFORMATION: 'debtors_information',
	RECHARGES_BONUS_FACTORS: 'recharges_bonus_factors',
	BILLING: 'billing',
};

export const month = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
];

export const DOCUMENTS = {
	USERTYPES: 'userTypes',
};

export const ERRORS = {
	DB: {},
	BUCKET: { NO_PARENTID: 'no_parentId', NO_FILE: 'no_file', NO_FOLDER: 'no_folder', FILE_EXISTS: 'file_exists' },
	AUTH: {},
	OTHER: {
		FAIL: '¡ALGO FALLO, INTENTE DE NUEVO!',
	},
	FORM: {
		FIX_ERRORS: '¡DEBE SOLUCIONAR ERRORES DEL FORMULARIO ANTES DE GUARDAR!',
	},
};

export const TIMEOUTS = { SHORTEST: 150, SHORTER: 250, SHORT: 500, NORMAL: 1000, MEDIUM: 2000, LONG: 2500 };

export const CLOUD_FUNCTIONS_BASEURL = 'https://us-central1-go-redbridge.cloudfunctions.net/';
export const CLOUD_FUNCTIONS = {
	QUALITAS: {
		VEHICLES_REGISTRY: 'qualitasGetVehicleRegistration',
		PAYMENT_GATEWAY: 'qualitasPaymentGateway',
		GET_INVOICES: 'qualitasGetInvoices',
		APPLY_PAYMENT: 'qualitasApplyPayment',
		SELF_POLIZE_EMISSION: 'qualitasSelfPolizeEmission',
		GET_COTIZATION: 'qualitasGetCotization',
	},
	GET_TIME: 'getTime',
	GET_FIREBASE_USER: 'getUser',
	CREATE_FIREBASE_USER: 'createFirebaseUser',
	UPDATE_FIREBASE_USER: 'updateFirebaseUser',
	DELETE_FIREBASE_USER: 'deleteFirebaseUser',
	ACCOUNT_CREATION_EMAIL: 'accountCreationEmail',
	ORGANIZATION_WELCOME_EMAIL: 'organizationWelcomeMail',
	SEND_VERIFICATION_CODE: 'sendVerificationCode',
	SEND_MESSAGE_EMAIL: 'sendMessageEmail',
	SEND_MUTIPLE_MESSAGE_EMAIL: 'sendMultipleEmail',
	GENERATE_PDF: 'generate_PDF',
	GENERATE_PDF2: 'generate_Other',
	UPLOAD_EXCEL: 'upload_excel',
	FILE_PROCESS: 'fileProcessDebtors',
	FILE_INTEGRATOR_PROCESS: 'fileProcessIntegrator',
};

export const LOCAL_STORAGE_ID = '@go_platform';

export const KEYS = {
	CURRENT_USER_ID: `${LOCAL_STORAGE_ID}:current_user_id`,
	ACTIVE_THEME: `${LOCAL_STORAGE_ID}:activeTheme`,
	SIGNIN_EMAIL: `${LOCAL_STORAGE_ID}:signin_email`,
};

/*
 * INPUTS
 */
export const INPUT_NO_AUTOCOMPLETE: any = {
	autoComplete: 'none',
};

/*
 * Helper Functions
 */
export const NOOP_CALLBACK = () => {};

export const HIDDEN_ORGANIZATIONS_IDS = ['go', 'system'];

export enum LOCAL_STORAGE_KEYS {
	LAST_VEHICLE_ID = 'go-platform:last-vehicle-id',
}

export const INSURANCE_ID_TEMPLATE = 'GO-PO-000000000000000000';
export const INVOICE_ID_TEMPLATE = 'GO-INV-000000000000000000';

export const BONDS_APPLICATION_STATUS = {
	REJECTED: 'Rechazado',
	RECEIVED: 'Recibido',
	IN_PROCESS: 'En proceso',
	EVALUATION: 'Evaluando',
	INCOMPLETE: 'Incompleto',
	INVALID_DOCUMENTS: 'Documentos inválidos',
	INITIAL: 'Iniciado',
	OTHER: 'Otra observación',
	// Request
	CLIENTS_LEGAL_ISSUES: 'Problemas Legales del Cliente',
	UNFAVORABLE_RISK_EVALUATION: 'Evaluación de Riesgo Desfavorable',
	POLICY_NONCOMPLIANCE: 'Incumplimiento de Políticas',
	CLIENT_LEGAL_ISSUES: 'Problemas Legales del Cliente',

	// Quotation
	REJECTED_PROPOSAL: 'Oferta Económica Rechazada',
	APPROVED_PROPOSAL: 'Oferta Económica Aprobada',
	LOW_SCORING: 'Scoring Bajo',
	HIGH_RISK: 'Riesgo Elevado',
	INSUFFICIENT_FINANCIAL_CAPACITY: 'Capacidad Financiera Insuficiente',
	CHANGING_ECONOMIC_LEGAL_REGULATORY_MARKET_CONDITIONS: 'Condiciones Económicas, Legales, Normativas o Mercado Cambiantes',
	DELAY_IN_DOCUMENT_SUBMISSION: 'Retraso en la Presentación de Documentos',
	TERMS_DISAGREEMENT: 'Desacuerdo en los Términos',
	INSUFFICIENT_CREDIT_HISTORY: 'Historial Crediticio Insuficiente',
	INCONSISTENCY_IN_INFORMATION: 'Inconsistencia en la Información',
	CHANGE_IN_COMPANY_STRATEGY: 'Cambio en la Estrategia de la Empresa',
	COMMUNICATION_ISSUES: 'Problemas de Comunicación',
	INTERNAL_RISK_REEVALUATION: 'Reevaluación de Riesgos Internos',
	UNFAVORABLE_SECTOR_EVALUATION: 'Evaluación Desfavorable del Sector',
	RISK_POLICY_ADJUSTMENTS: 'Ajustes Política de Riesgo',
	RISK_RATE_ADJUSTMENTS: 'Ajustes de la Tasa',
	FRAUD_DETECTION: 'Detección de Fraude',
	DISTRIBUTION_CHANNEL_ISSUES: 'Problemas con el Canal de Distribución',
	CREDIT_COMMITTEE_REJECTION: 'Rechazo por el Comité de Crédito',
	MISSING_TEMPLATE_SUBMISSION: 'Falta de Presentación de Plantilla',
	INSUFFICIENT_GUARANTEE: 'Garantía Insuficiente',
	FAILED_VERIFICATION: 'Verificación Fallida',
	EXPIRED_DEADLINE: 'Plazo Vencido',
	DUPLICATE_REQUEST: 'Solicitud Duplicada',
	// Issues
};

export enum BILLING_STATUS {
	REVIEW = 'En revisión',
	SEND_PREINVOICE = 'Enviar Pre-Factura',
	ISSUES_INVOICE = 'Emitir Factura',
	PENDING_PAYMENT = 'Pendiente de Pago',
	INVOICE_PAID = 'Factura Pagada',
	RETURNED_COMMENTS = 'Devuelta con Observaciones',
}

export const STATE_PROCESS = {
	REQUEST: 'request',
	QUOTATION: 'quotation',
	Issuance: 'issuance',
};

export const defaultColDef: ColDef = {
	...globalColDef,
	sortable: true,
	filter: true,
	editable: false,
};

export const documentCardNames = {
	acuerdo_confidencialidad: 'Acuerdos de Confidencialidad',
	estados_financieros: 'Estados Financieros auditados de los últimos (3) años',
	estatutos_organizacion: 'Estatuto de la organización',
	personeria_juridica: 'Personeria jurídica',
	reglamento_cobro: 'Reglamento de cobro',
	reglamento_credito: 'Reglamento de crédito',
	forma_de_pago: 'Forma de Pago',
};

export const productivityKeys = [
	'perday',
	'judicialcollection',
	'morethanonehundredandeightydays',
	'ninetyonetoonehundredandeightydays',
	'onetothirtydays',
	'sixtyonetoninetydays',
	'thirtyonetosixtydays',
];

export const consumptionKeys = [
	'judicialcollection2',
	'morethanonehundredandeightydays2',
	'ninetyonetoonehundredandeightydays2',
	'onetothirtydays2',
	'perday2',
	'sixtyonetoninetydays2',
	'thirtyonetosixtydays2',
];

export const housingKeys = [
	'judicialcollection3',
	'morethanonehundredandeightydays3',
	'ninetyonetoonehundredandeightydays3',
	'onetothirtydays3',
	'perday3',
	'sixtyonetoninetydays3',
	'thirtyonetosixtydays3',
];

export const REASONS_FOR_BOND_APPLCIATION_REJECTION = [
	{ value: BONDS_APPLICATION_STATUS.INCOMPLETE, label: 'Documentación incompleta' },
	{ value: BONDS_APPLICATION_STATUS.INCOMPLETE, label: 'Falta de Respuesta del Cliente' },
	{ value: BONDS_APPLICATION_STATUS.UNFAVORABLE_RISK_EVALUATION, label: 'Evaluación de Riesgo Desfavorable' },
	{ value: BONDS_APPLICATION_STATUS.INVALID_DOCUMENTS, label: 'Información Incorrecta' },
	{ value: BONDS_APPLICATION_STATUS.POLICY_NONCOMPLIANCE, label: 'Incumplimiento de Políticas' },

	{ value: BONDS_APPLICATION_STATUS.CLIENT_LEGAL_ISSUES, label: 'Problemas Legales del Cliente' },

	{ value: BONDS_APPLICATION_STATUS.LOW_SCORING, label: 'Scoring Bajo' },
	{ value: BONDS_APPLICATION_STATUS.HIGH_RISK, label: 'Riesgo Elevado' },

	{ value: BONDS_APPLICATION_STATUS.INSUFFICIENT_FINANCIAL_CAPACITY, label: 'Capacidad Financiera Insuficiente' },
	{
		value: BONDS_APPLICATION_STATUS.CHANGING_ECONOMIC_LEGAL_REGULATORY_MARKET_CONDITIONS,
		label: 'Condiciones Económicas, Legales, Normativas o Mercado Cambiantes',
	},
	{ value: BONDS_APPLICATION_STATUS.DELAY_IN_DOCUMENT_SUBMISSION, label: 'Retraso en la Presentación de Documentos' },
	{ value: BONDS_APPLICATION_STATUS.TERMS_DISAGREEMENT, label: 'Desacuerdo en los Términos' },
	{ value: BONDS_APPLICATION_STATUS.INSUFFICIENT_CREDIT_HISTORY, label: 'Historial Crediticio Insuficiente' },
	{ value: BONDS_APPLICATION_STATUS.INCONSISTENCY_IN_INFORMATION, label: 'Inconsistencia en la Información' },
	{ value: BONDS_APPLICATION_STATUS.CHANGE_IN_COMPANY_STRATEGY, label: 'Cambio en la Estrategia de la Empresa' },
	{ value: BONDS_APPLICATION_STATUS.COMMUNICATION_ISSUES, label: 'Problemas de Comunicación' },
	{ value: BONDS_APPLICATION_STATUS.INTERNAL_RISK_REEVALUATION, label: 'Reevaluación de Riesgos Internos' },
	{ value: BONDS_APPLICATION_STATUS.UNFAVORABLE_SECTOR_EVALUATION, label: 'Evaluación Desfavorable del Sector' },
	{ value: BONDS_APPLICATION_STATUS.RISK_POLICY_ADJUSTMENTS, label: 'Ajustes Política de Riesgo' },
	{ value: BONDS_APPLICATION_STATUS.FRAUD_DETECTION, label: 'Detección de Fraude' },
	{ value: BONDS_APPLICATION_STATUS.DISTRIBUTION_CHANNEL_ISSUES, label: 'Problemas con el Canal de Distribución' },
	{ value: BONDS_APPLICATION_STATUS.TERMS_DISAGREEMENT, label: 'Desacuerdo en la Tarifa' },
	{ value: BONDS_APPLICATION_STATUS.CREDIT_COMMITTEE_REJECTION, label: 'Rechazo por el Comité de Crédito' },
	{ value: BONDS_APPLICATION_STATUS.MISSING_TEMPLATE_SUBMISSION, label: 'Falta de Presentación de Plantilla' },
	{ value: BONDS_APPLICATION_STATUS.INSUFFICIENT_GUARANTEE, label: 'Garantía Insuficiente' },
	{ value: BONDS_APPLICATION_STATUS.FAILED_VERIFICATION, label: 'Verificación Fallida' },
	{ value: BONDS_APPLICATION_STATUS.EXPIRED_DEADLINE, label: 'Plazo Vencido' },
	{ value: BONDS_APPLICATION_STATUS.DUPLICATE_REQUEST, label: 'Solicitud Duplicada' },
	{ value: BONDS_APPLICATION_STATUS.OTHER, label: 'Otro' },
];

export const REASONS_FOR_BOND_APPLCIATION_CUSTOMER = [
	{ value: BONDS_APPLICATION_STATUS.INVALID_DOCUMENTS, label: 'Información Incorrecta' },
	{ value: BONDS_APPLICATION_STATUS.TERMS_DISAGREEMENT, label: 'Desacuerdo en la Tarifa' },
	{ value: BONDS_APPLICATION_STATUS.HIGH_RISK, label: 'Riesgo Elevado' },
	{
		value: BONDS_APPLICATION_STATUS.CHANGING_ECONOMIC_LEGAL_REGULATORY_MARKET_CONDITIONS,
		label: 'Condiciones Económicas, Legales, Normativas o Mercado Cambiantes',
	},
	{ value: BONDS_APPLICATION_STATUS.INCONSISTENCY_IN_INFORMATION, label: 'Inconsistencia en la Información' },
	{ value: BONDS_APPLICATION_STATUS.CHANGE_IN_COMPANY_STRATEGY, label: 'Cambio en la Estrategia de la Empresa' },
	{ value: BONDS_APPLICATION_STATUS.COMMUNICATION_ISSUES, label: 'Problemas de Comunicación' },
	{ value: BONDS_APPLICATION_STATUS.INTERNAL_RISK_REEVALUATION, label: 'Reevaluación de Riesgos Internos' },
	{ value: BONDS_APPLICATION_STATUS.RISK_POLICY_ADJUSTMENTS, label: 'Ajustes Política de Riesgo' },
	{ value: BONDS_APPLICATION_STATUS.FRAUD_DETECTION, label: 'Detección de Fraude' },
	{ value: BONDS_APPLICATION_STATUS.DISTRIBUTION_CHANNEL_ISSUES, label: 'Problemas con el Canal de Distribución' },
	{ value: BONDS_APPLICATION_STATUS.MISSING_TEMPLATE_SUBMISSION, label: 'Falta de Presentación de Plantilla' },
	{ value: BONDS_APPLICATION_STATUS.INSUFFICIENT_GUARANTEE, label: 'Garantía Insuficiente' },
	{ value: BONDS_APPLICATION_STATUS.FAILED_VERIFICATION, label: 'Verificación Fallida' },
	{ value: BONDS_APPLICATION_STATUS.OTHER, label: 'Otro' },
];

export const REASONS_FOR_BOND_APPLCIATION_CUSTOMER_BILLING = [
	{ value: BONDS_APPLICATION_STATUS.INVALID_DOCUMENTS, label: 'Información Incorrecta' },
	{ value: BONDS_APPLICATION_STATUS.TERMS_DISAGREEMENT, label: 'Desacuerdo en la Tarifa' },
];

export const REASONS_FOR_BOND_APPLCIATION_CUSTOMER_PROPOSAL = [
	{ value: BONDS_APPLICATION_STATUS.INVALID_DOCUMENTS, label: 'Información Incorrecta' },
	{ value: BONDS_APPLICATION_STATUS.TERMS_DISAGREEMENT, label: 'Desacuerdo en la Tarifa' },
	{ value: BONDS_APPLICATION_STATUS.OTHER, label: 'Otro' },
];

export const isoCountries = {
	AF: 'AFGANISTÁN',
	AX: 'ISLAS ALAND',
	AL: 'ALBANIA',
	DZ: 'ALGERIA',
	AS: 'SAMOA AMERICANA',
	AD: 'ANDORRA',
	AO: 'ANGOLA',
	AI: 'ANGUILA',
	AQ: 'ANTÁRTIDA',
	AG: 'ANTIGUA Y BARBUDA',
	AR: 'ARGENTINA',
	AM: 'ARMENIA',
	AW: 'ARUBA',
	AU: 'AUSTRALIA',
	AT: 'AUSTRIA',
	AZ: 'AZERBAIYÁN',
	BS: 'BAHAMAS',
	BH: 'BARÉIN',
	BD: 'BANGLADESH',
	BB: 'BARBADOS',
	BY: 'BIELORRUSIA',
	BE: 'BÉLGICA',
	BZ: 'BELICE',
	BJ: 'BENÍN',
	BM: 'BERMUDAS',
	BT: 'BUTÁN',
	BO: 'BOLIVIA',
	BA: 'BOSNIA HERZEGOVINA',
	BW: 'BOTSWANA',
	BV: 'ISLA BOUVET',
	BR: 'BRASIL',
	IO: 'TERRITORIO BRITÁNICO DEL OCÉANO ÍNDICO',
	BN: 'BRUNÉI',
	BG: 'BULGARIA',
	BF: 'BURKINA FASO',
	BI: 'BURUNDI',
	KH: 'CAMBOYA',
	CM: 'CAMERÚN',
	CA: 'CANADÁ',
	CV: 'CABO VERDE',
	KY: 'ISLAS CAYMAN',
	CF: 'REPUBLICA CENTROAFRICANA',
	TD: 'CHAD',
	CL: 'CHILE',
	CN: 'CHINA',
	CX: 'KIRITIMATI',
	CC: 'ISLAS COCOS',
	CO: 'COLOMBIA',
	KM: 'COMOROS',
	CG: 'CONGO',
	CD: 'REPUBLICA DEMOCRATICA DEL CONGO',
	CK: 'ISLAS COOK',
	CR: 'COSTA RICA',
	CI: 'COSTA DE MARFIL',
	HR: 'CROACIA',
	CU: 'CUBA',
	CY: 'CHIPRE',
	CZ: 'REPÚBLICA CHECA',
	DK: 'DINAMARCA',
	DJ: 'YIBUTI',
	DM: 'DOMINICA',
	DO: 'REPÚBLICA DOMINICANA',
	EC: 'ECUADOR',
	EG: 'EGIPTO',
	SV: 'EL SALVADOR',
	GQ: 'GUINEA EQUATORIAL',
	ER: 'ERITREA',
	EE: 'ESTONIA',
	ET: 'ETIOPÍA',
	FK: 'ISLAS MALVINAS',
	FO: 'ISLAS FEROE',
	FJ: 'FIYI',
	FI: 'FINLANDIA',
	FR: 'FRANCIA',
	GF: 'GUAYANA FRANCESA',
	PF: 'POLINESIA FRANCESA',
	TF: 'TERRITORIOS FRANCESES DEL SUR',
	GA: 'GABÓN',
	GM: 'GAMBIA',
	GE: 'GEORGIA',
	DE: 'ALEMANIA',
	GH: 'GHANA',
	GI: 'GIBRALTAR',
	GR: 'GRECIA',
	GL: 'GREENLAND',
	GD: 'GRANADA',
	GP: 'GUADALUPE',
	GU: 'GUAM',
	GT: 'GUATEMALA',
	GG: 'GUERNSEY',
	GN: 'GUINEA',
	GW: 'GUINEA-BISSAU',
	GY: 'GUYANA',
	HT: 'HAITÍ',
	HM: 'ISLA HEARD E ISLAS MCDONALD',
	VA: 'CIUDAD DEL VATICANO',
	HN: 'HONDURAS',
	HK: 'HONG KONG',
	HU: 'HUNGRÍA',
	IS: 'ISLANDIA',
	IN: 'INDIA',
	ID: 'INDONESIA',
	IR: 'IRÁN',
	IQ: 'IRAQ',
	IE: 'IRLANDA',
	IM: 'ISLA DE MAN',
	IL: 'ISRAEL',
	IT: 'ITALIA',
	JM: 'JAMAICA',
	JP: 'JAPÓN',
	JE: 'JERSEY',
	JO: 'JORDANIA',
	KZ: 'KAZAJISTÁN',
	KE: 'KENIA',
	KI: 'KIRIBATI',
	KR: 'KOREA',
	KW: 'KUWAIT',
	KG: 'KIRGUISTÁN',
	LA: 'LAOS',
	LV: 'LETONIA',
	LB: 'LÍBANO',
	LS: 'LESOTO',
	LR: 'LIBERIA',
	LY: 'LIBIA',
	LI: 'LIECHTENSTEIN',
	LT: 'LITUANIA',
	LU: 'LUXEMBURGO',
	MO: 'MACAO',
	MK: 'MACEDONIA',
	MG: 'MADAGASCAR',
	MW: 'MALAUI',
	MY: 'MALASIA',
	MV: 'MALDIVAS',
	ML: 'MALI',
	MT: 'MALTA',
	MH: 'ISLAS MARSHALL',
	MQ: 'MARTINICA',
	MR: 'MAURITANIA',
	MU: 'MAURICIO',
	YT: 'MAYOTTE',
	MX: 'MÉXICO',
	FM: 'MICRONESIA',
	MD: 'MOLDAVIA',
	MC: 'MÓNACO',
	MN: 'MONGOLIA',
	ME: 'MONTENEGRO',
	MS: 'MONTSERRAT',
	MA: 'MARRUECOS',
	MZ: 'MOZAMBIQUE',
	MM: 'MYANMAR (BIRMANIA)',
	NA: 'NAMIBIA',
	NR: 'NAURU',
	NP: 'NEPAL',
	NL: 'HOLANDA',
	AN: 'ANTILLAS HOLANDESAS',
	NC: 'NUEVA CALEDONIA',
	NZ: 'NUEVA ZELANDA',
	NI: 'NICARAGUA',
	NE: 'NÍGER',
	NG: 'NIGERIA',
	NU: 'NIUE',
	NF: 'ISLA NORFOLK',
	MP: 'ISLAS MARIANAS DEL NORTE',
	NO: 'NORUEGA',
	OM: 'OMÁN',
	PK: 'PAKISTÁN',
	PW: 'PALAU',
	PS: 'PALESTINA',
	PA: 'PANAMÁ',
	PG: 'PAPUA NUEVA GUINEA',
	PY: 'PARAGUAY',
	PE: 'PERÚ',
	PH: 'FILIPINAS',
	PN: 'ISLAS PITCAIRN',
	PL: 'POLONIA',
	PT: 'PORTUGAL',
	PR: 'PUERTO RICO',
	QA: 'QATAR',
	RE: 'REUNIÓN',
	RO: 'RUMANÍA',
	RU: 'RUSIA',
	RW: 'RUANDA',
	BL: 'SAN BARTOLOMÉ',
	SH: 'SANTA HELENA',
	KN: 'SAN CRISTÓBAL Y NIEVES',
	LC: 'SANTA LUCÍA',
	MF: 'SAN MARTÍN',
	PM: 'SAN PEDRO Y MIQUELÓN',
	VC: 'SAN VICENTE Y LAS GRANADINAS',
	WS: 'SAMOA',
	SM: 'SAN MARINO',
	ST: 'SANTO TOMÉ Y PRÍNCIPE',
	SA: 'ARABIA SAUDITA',
	SN: 'SENEGAL',
	RS: 'SERBIA',
	SC: 'SEYCHELLES',
	SL: 'SIERRA LEONA',
	SG: 'SINGAPUR',
	SK: 'ESLOVAQUIA',
	SI: 'ESLOVENIA',
	SB: 'ISLAS SALOMÓN',
	SO: 'SOMALIA',
	ZA: 'SUR ÁFRICA',
	GS: 'ISLAS GEORGIAS DEL SUR Y SANDWICH DEL SUR',
	ES: 'ESPAÑA',
	LK: 'SRI LANKA',
	SD: 'SUDÁN',
	SR: 'SURINAM',
	SJ: 'SVALBARD Y JAN MAYEN',
	SZ: 'ESUATINI',
	SE: 'SUECIA',
	CH: 'SUIZA',
	SY: 'SIRIA',
	TW: 'TAIWÁN',
	TJ: 'TAYIKISTÁN',
	TZ: 'TANZANIA',
	TH: 'TAILANDIA',
	TL: 'TIMOR ORIENTAL',
	TG: 'TOGO',
	TK: 'TOKELAU',
	TO: 'TONGA',
	TT: 'TRINIDAD Y TOBAGO',
	TN: 'TÚNEZ',
	TR: 'TURQUIA',
	TM: 'TURKMENISTÁN',
	TC: 'ISLAS TURCAS Y CAICOS',
	TV: 'TUVALU',
	UG: 'UGANDA',
	UA: 'UCRAINA',
	AE: 'EMIRATOS ÁRABES UNIDOS',
	GB: 'REINO UNIDO',
	US: 'ESTADOS UNIDOS',
	UM: 'ISLAS ULTRAMARINAS MENORES DE ESTADOS UNIDOS',
	UY: 'URUGUAY',
	UZ: 'UZBEKISTÁN',
	VU: 'VANUATU',
	VE: 'VENEZUELA',
	VN: 'VIETNAM',
	VG: 'ISLAS VÍRGENES BRITÁNICAS',
	VI: 'ISLAS VÍRGENES DE LOS ESTADOS UNIDOS',
	WF: 'WALLIS Y FUTUNA',
	EH: 'SAHARA OCCIDENTAL',
	YE: 'YEMEN',
	ZM: 'ZAMBIA',
	ZW: 'ZIMBABUE',
};
