import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, InfiniteRowModelModule } from '@ag-grid-community/all-modules';

/**
 * Default module exports
 */
export const defaultModules = [ClientSideRowModelModule, InfiniteRowModelModule];

/**
 * Default column definition
 */
export const globalColDef: ColDef = {
	minWidth: 130,
	suppressMovable: true,
	flex: 1,
	resizable: true,
	wrapText: true,
	headerComponentParams: {
		template:
			'<div class="ag-cell-label-container" role="presentation">' +
			'  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
			'  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
			'    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
			'    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
			'    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
			'    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
			'    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;text-align:center"></span>' +
			'    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
			'  </div>' +
			'</div>',
	},
};
